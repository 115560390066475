import React from "react";
import SliderComponent from './Slider';
import ServicesComponent from './Services';
import VideoComponent from './Video';
import DescriptionComponent from './Description';
import ProductComponent from './Product';
import StoreLocatorComponent from './StoreLocator';
import BannerComponent from './Banner';
import BlogComponent from './Blog';

const HomeIndexComponent = () =>{
    return (
        <div>
            <SliderComponent/>
            <ServicesComponent/>
            <VideoComponent/>
            <DescriptionComponent/>
            <ProductComponent/>
            <StoreLocatorComponent/>
            <BannerComponent/>
            <BlogComponent/>
        </div>
    )
}

export default HomeIndexComponent;