import React from "react";
import './Home.css';
import Flickity from 'react-flickity-component'

const flickityOptions = {
    initialIndex: 1,
    autoPlay: 1500,
    pauseAutoPlayOnHover: true,
    prevNextButtons: false,
    wrapAround: true,
    resize: true,
    fullscreen: false,
    contain: true,
    pageDots: false,
    freeScroll: false,
    adaptiveHeight: false,
    setGallerySize: true,
}

const SliderComponent = () => {

    return(
            // <div className="carousel" style={backImg} data-flickity='{ "autoPlay": true }'>
            //     <div className="carousel-cell"/>
            //     <div className="carousel-cell"/>
            //     <div className="carousel-cell"/>
            //     <div className="carousel-cell"/>
            // </div>
        <>
        <section>
            <div className="sec">
                <Flickity
                    className={'carousel-cell'} // default ''
                    elementType={'div'} // default 'div'
                    options={flickityOptions} // takes flickity options {}
                    disableImagesLoaded={true} // default false
                    reloadOnUpdate={true} // default false
                    static={true} // default false
                    >
                    <img className="back-image" src="/1.jpg" alt="Ajman Furniture One"/>
                    <img className="back-image" src="/2.jpg" alt="Ajman Furniture One"/>
                    <img className="back-image" src="/3.jpg" alt="Ajman Furniture One"/>
                    <img className="back-image" src="/4.jpg" alt="Ajman Furniture One"/>
                    <img className="back-image" src="/5.jpg" alt="Ajman Furniture One"/>
                </Flickity>
                {/* <img className="back-image" src="/mng.JPG" alt="Ajman Furniture One"/> */}
            </div>
        </section>
        </>
    );
}

export default SliderComponent;