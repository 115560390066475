import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import './main.css';

// import HomeIndexComponent from '../HomeComponents/HomeIndexComponent';
// import HistoryIndexComponent from '../HistoryComponent/HistoryIndexComponent';
// import ShopIndexComponent from '../ShopComponent/ShopIndexComponent';
// import PortfolioIndexComponent from '../PortfolioComponent/PortfolioIndexComponent';
// import BlogIndexComponent from '../BlogComponent/BlogIndexComponent';
// import StoreLocatorIndexComponent from '../StoreLocatorComponent/StoreLocatorIndexComponent';
// import FAQIndexComponent from '../FAQComponent/FAQIndexComponent';
// import ContactIndexComponent from '../ContactComponent/ContactIndexComponent';

const NavComponent = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    return(
        <div>
            <header>
                <Link to='/' className="logo">
                    <img src="./AjmanFurnitureMini.png" alt="Ajman Furniture | Leading Furniture Store in UAE"/>
                </Link>
                <button className="hamburger" onClick={() => { setIsNavExpanded(!isNavExpanded) }}>
        {/* icon from heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
                <nav className='navigation-menu'>
                    <ul className="list-item">
                        <li>
                            <Link to='/'>Home</Link>
                        </li>
                        <li>
                            <a href="https://www.asgharfurniture.ae/about-us/" target="_blank" rel="noreferrer">History</a>
                        </li>
                        <li>
                        <a href="https://www.asgharfurniture.ae/shop-catalog/" target="_blank" rel="noreferrer">Shop</a>
                        </li>
                        {/* <li>
                            <Link to='/portfolio'>Portfolio</Link>
                        </li> */}
                        <li>
                        <a href="https://www.asgharfurniture.ae/blogs/" target="_blank" rel="noreferrer">Blog</a>
                        </li>
                        <li>
                        <a href="https://www.asgharfurniture.ae/frequently-asked-questions/" target="_blank" rel="noreferrer">FAQ</a>
                        </li>
                        <li>
                        <a href="https://www.asgharfurniture.ae/contact-us/" target="_blank" rel="noreferrer">Contact Us</a>
                        </li>
                    </ul>
                </nav>
        </div>
            </header>
        </div>
        
    );
}

export default NavComponent;