import React from "react";

const BannerComponent = () => {
    const handleClick = () => {
        window.location.href = "https://www.asgharfurniture.ae/on-sale/#ss-2799";
    }
    return(
        <section>
            <div>
                <img onClick={()=> handleClick()} className="banner-image" src="2599-web-banner-sofa-desktop.webp" alt="Asghar Furniture Sofa Sale UAE Online"/>
            </div>
        </section>
    );
}

export default BannerComponent;