import React from "react";

const VideoComponent = () => {
    return(
        <section>
            <div className="video-cont">
                <img className="video-image" src="./video.jpg" alt="Asghar Furniture Services"/>
                <button className="video-play-button">></button>
            </div>
        </section>
    );
}

export default VideoComponent;