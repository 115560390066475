import React from "react";

const BlogComponent = () => {
    const handleBlogOne = () => {
        window.location.href = "https://www.asgharfurniture.ae/interior-design/top-10-best-sectional-sofas-of-2022-that-should-cover-your-atoz-furniture-decor-ideas/";
    }
    const handleBlogTwo = () => {
        window.location.href = "https://www.asgharfurniture.ae/furniture-decor/customized-furniture-affordable-prices-your-home-the-way-you-want-it/";
    }
    const handleBlogThree = () => {
        window.location.href = "https://www.asgharfurniture.ae/interior-design/minimalist-style-maximize-your-small-space-livingrooms/";
    }

    return(
        <section>
            <div>
                <h1 className="blog-topic">Home Decor Blog</h1>
                <div className="blog">
                    <div className="blog-left" onClick={()=> handleBlogOne()}>
                        <img className="blog-thumbnail" src="./Top-10-Best-Sectional-Sofas.jpg" alt="Leading Furniture Store in UAE" />
                        <h4 className="topic-border">Top 10 Best Sectional Sofas of 2022 that should Cover Your AtoZ Furniture Decor ideas</h4>
                        <p className="blog-date">ON 12.SEP.2022</p>
                    </div>
                    <div className="blog-left" onClick={()=> handleBlogTwo()}>
                        <img className="blog-thumbnail" src="./Minimalist-Style.jpg" alt="Leading Furniture Store in UAE" />
                        <h4 className="topic-border">Customized Furniture: Affordable Prices, Your Home, The Way You Want It</h4>
                        <p className="blog-date">ON 05.SEP.2022</p>
                    </div>
                    <div className="blog-left" onClick={()=> handleBlogOne()}>
                        <img className="blog-thumbnail" src="./keelin-tv-stand.jpg" alt="Leading Furniture Store in UAE" />
                        <h4 className="topic-border">Top 10 Best Sectional Sofas of 2022 that should Cover Your AtoZ Furniture Decor ideas</h4>
                        <p className="blog-date">ON 26.AUG.2022</p>
                    </div>
                    <div className="blog-left" onClick={()=> handleBlogThree()}>
                        <img className="blog-thumbnail" src="./Customized-Furniture.jpg" alt="Leading Furniture Store in UAE" />
                        <h4 className="topic-border">Minimalist Style &#8211; Maximize Your Small Space Living and Bedrooms</h4>
                        <p className="blog-date">ON 03.SEP.2022</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BlogComponent;