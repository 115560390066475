import React from "react";

const handleClick = (event) => {
    if(event.target.id === "chelsea"){
        window.location.href= 'https://www.asgharfurniture.ae/living-room/sectional-sofa/chelsea-reversible-sofa/';
    }else if(event.target.id === "farah"){
        window.location.href= "https://www.asgharfurniture.ae/bed-room/bed/farah-upholstered-bed/";
    }else if(event.target.id === "trixie"){
        window.location.href= "https://www.asgharfurniture.ae/living-room/center-table/trixie-center-table/";
    }else if(event.target.id === "apollo"){
        window.location.href= "https://www.asgharfurniture.ae/living-room/sectional-sofa/apollo-sectional-sofa/";
    }else if(event.target.id === "ingles"){
        window.location.href= "https://www.asgharfurniture.ae/living-room/sectional-sofa/ingles-reversible-sofa/";
    }else if(event.target.id === "aida"){
        window.location.href= "https://www.asgharfurniture.ae/bed-room/bed/aida-upholstered-bed/";
    }else if(event.target.id === "dwarf"){
        window.location.href= "https://www.asgharfurniture.ae/bed-room/nightstands/dwarf-2-drawer-nightstand/";
    }else if(event.target.id === "chester"){
        window.location.href= "https://www.asgharfurniture.ae/living-room/sofa/chester-hill-sofa/";
    }
}

const ProductComponent = () => {
    return(
        <section>
            <div className="product-parent">
                <div id="chelsea" className="product-item" onClick={(event) => handleClick(event)}>
                    <img className="product-image" src="./chelsea-reversible-sofa.jpg" alt="Chelsea Reversible Sectional Sofa UAE Living Room Furniture Online UAE"/>
                    <a href="https://www.asgharfurniture.ae/living-room/sectional-sofa/chelsea-reversible-sofa/" className="product-title">Chelsea Reversible Sofa</a>
                    <p className="product-price">AED 1,499.00</p>
                    <p className="product-category">Living Room Furniture</p>
                </div>
                <div id="farah" className="product-item" onClick={(event) => handleClick(event)}>
                    <img className="product-image" src="./Nyla-Premium-Designer-Bed.jpg" alt="Nyla Premium Designer Bed Queen King Superking Bed Bedroom Furniture UAE"/>
                    <a href="https://www.asgharfurniture.ae/bed-room/bed/nyla-premium-designer-bed/" className="product-title">Nyla Premium Designer Bed</a>
                    <p className="product-price">AED 1,499.00</p>
                    <p className="product-category">Bedroom Furniture</p>
                </div>
                <div id="trixie" className="product-item" onClick={(event) => handleClick(event)}>
                    <img className="product-image" src="./Trixie-Center-Table.jpg" alt="Trixie Center Table Living Room Furniture Online UAE"/>
                    <a href="https://www.asgharfurniture.ae/living-room/center-table/trixie-center-table/" className="product-title">Trixie Center Table</a>
                    <p className="product-price">AED 899.00</p>
                    <p className="product-category">Living Room Furniture</p>
                </div>
                <div id="apollo" className="product-item" onClick={(event) => handleClick(event)}>
                    <img className="product-image" src="./Apollo-Sectional-Sofa.jpg" alt="Apollo Sectional Sofa UAE Living Room Furniture Online UAE"/>
                    <a href="https://www.asgharfurniture.ae/living-room/sectional-sofa/apollo-sectional-sofa/" className="product-title">Apollo Sectional Sofa</a>
                    <p className="product-price">AED 2,599.00</p>
                    <p className="product-category">Living Room Furniture</p>
                </div>
            </div>
            <div className="product-parent">
                <div id="ingles" className="product-item" onClick={(event) => handleClick(event)}>
                    <img className="product-image" src="./Ingles-Reversible-Sofa.jpg" alt="Ingles Reversible Sectional Sofa UAE Living Room Furniture Online UAE"/>
                    <a href="https://www.asgharfurniture.ae/living-room/sectional-sofa/ingles-reversible-sofa/" className="product-title">Ingles Reversible Sofa</a>
                    <p className="product-price">AED 1,999.00</p>
                    <p className="product-category">Living Room Furniture</p>
                </div>
                <div id="aida" className="product-item" onClick={(event) => handleClick(event)}>
                    <img className="product-image" src="./Aida-Upholstered-Bed.jpg" alt="Aida Upholstered Bed Queen King Superking Bed Bedroom Furniture UAE"/>
                    <a href="https://www.asgharfurniture.ae/bed-room/bed/aida-upholstered-bed/" className="product-title">Aida Upholstered Bed</a>
                    <p className="product-price">AED 999.00</p>
                    <p className="product-category">Bedroom Furniture</p>
                </div>
                <div id="dwarf" className="product-item" onClick={(event) => handleClick(event)}>
                    <img className="product-image" src="./Dwarf_2-Drawer_Nightstand.jpg" alt="Dwarf 2-Drawer Nightstand Bedroom Furniture Online UAE"/>
                    <a href="https://www.asgharfurniture.ae/bed-room/nightstands/dwarf-2-drawer-nightstand/" className="product-title">Dwarf 2-Drawer Nightstand</a>
                    <p className="product-price">AED 499.00</p>
                    <p className="product-category">Bedroom Furniture</p>
                </div>
                <div id="chester" className="product-item" onClick={(event) => handleClick(event)}>
                    <img className="product-image" src="./Chester-Hill-Sofa.jpg" alt="Chester Hill Sofa UAE Living Room Furniture Online UAE"/>
                    <a href="https://www.asgharfurniture.ae/living-room/sofa/chester-hill-sofa/" className="product-title">Chester Hill Sofa</a>
                    <p className="product-price">AED 999.00</p>
                    <p className="product-category">Living Room Furniture</p>
                </div>
            </div>
        </section>
    );
}

export default ProductComponent;