import React from "react";

const CatRedirect = (event) => {
    if(event.target.id === "afSofa"){
        window.location.href = "https://www.asgharfurniture.ae/living-room/sectional-sofa/"
    }
    else if(event.target.id === "afBed"){
        window.location.href = "https://www.asgharfurniture.ae/bed-room/bed/"
    }
    else if(event.target.id === "afDresser"){
        window.location.href = "https://www.asgharfurniture.ae/bed-room/dressing-table/"
    }
    else if(event.target.id === "afWardrobe"){
        window.location.href = "https://www.asgharfurniture.ae/bed-room/wardrobe/"
    }
    else if(event.target.id === "afTv"){
        window.location.href = "https://www.asgharfurniture.ae/living-room/tv-cabinet/"
    }
    else if(event.target.id === "afNightstand"){
        window.location.href = "https://www.asgharfurniture.ae/bed-room/nightstands/"
    }
}

const ServicesComponent = () => {
    return(
        <section>
            <div className="images">
                <div className="item-column">
                    <img src="./sofa.png" id="afSofa" onClick={(event) => CatRedirect(event)} alt="Sofa Asghar Furniture Buy Online Dubai UAE" className="icon"/>
                    <a className="cat-title" href="https://www.asgharfurniture.ae/living-room/sectional-sofa/">Sectional Sofa</a>
                </div>
                <div className="item-column">
                    <img src="./bed.png" id="afBed" onClick={(event) => CatRedirect(event)} alt="Bed Asghar Furniture Buy Online Dubai UAE" className="icon"/>
                    <a className="cat-title" href="https://www.asgharfurniture.ae/bed-room/bed/">Bed</a>
                </div>
                <div className="item-column">
                    <img src="./dresser.png" id="afDresser" onClick={(event) => CatRedirect(event)} alt="Dresser Asghar Furniture Buy Online Dubai UAE" className="icon"/>
                    <a className="cat-title" href="https://www.asgharfurniture.ae/bed-room/dressing-table/">Dresser</a>
                </div>
                <div className="item-column">
                    <img src="./wardrobe.png" id="afWardrobe" onClick={(event) => CatRedirect(event)} alt="Wardrobe Asghar Furniture Buy Online Dubai UAE" className="icon"/>
                    <a className="cat-title" href="https://www.asgharfurniture.ae/bed-room/wardrobe/">Wardrobe</a>
                </div>
                <div className="item-column">
                    <img src="./tv.png" id="afTv" onClick={(event) => CatRedirect(event)} alt="TV Cabinet Asghar Furniture Buy Online Dubai UAE" className="icon"/>
                    <a className="cat-title" href="https://www.asgharfurniture.ae/living-room/tv-cabinet/">TV Cabinet</a>
                </div>
                <div className="item-column">
                    <img src="./nightstand.png" id="afNightstand" onClick={(event) => CatRedirect(event)} alt="Nightstand Asghar Furniture Buy Online Dubai UAE" className="icon"/>
                    <a className="cat-title" href="https://www.asgharfurniture.ae/bed-room/nightstands/">Nightstand</a>
                </div>
            </div>
            <div>
                
                
            </div>
        </section>
    );
}

export default ServicesComponent;