import React from "react";

const StoreLocatorComponent = () => {
    const contactRed =  () => {
        window.location.href = "https://www.asgharfurniture.ae/contact-us/";
    }
    return(
        <section>
            <div className="store-locator-widget" style={{ backgroundImage: "url(/bg-map.jpg)" }}>
                <div className="store-locator-left">
                    <h2>Find a store near by</h2>
                    <p>Search a store near as your convinient | We are available throughout the entire UAE</p>
                </div>
                <div className="store-locator-right">
                <a href="https://www.google.com/maps/search/Asghar+Furniture/@25.2189924,55.343294,11z/data=!3m1!4b1?entry=ttu" target="_blank" rel="noreferrer">
                <img className="store-locator-arrow" src="arrow.png" alt="Store Locator" onClick={() => contactRed()} />
                </a>
                </div>
            </div>
        </section>
    );
}

export default StoreLocatorComponent;