import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import NavComponent from './Component/MainComponent/Navigation';
import FooterComponent from './Component/MainComponent/Footer';
import HomeIndexComponent from './Component/HomeComponents/HomeIndexComponent';
// import HistoryIndexComponent from './Component/HistoryComponent/HistoryIndexComponent';
// import ShopIndexComponent from './Component/ShopComponent/ShopIndexComponent';
// import PortfolioIndexComponent from './Component/PortfolioComponent/PortfolioIndexComponent';
// import BlogIndexComponent from './Component/BlogComponent/BlogIndexComponent';
// import StoreLocatorIndexComponent from './Component/StoreLocatorComponent/StoreLocatorIndexComponent';
// import FAQIndexComponent from './Component/FAQComponent/FAQIndexComponent';
// import ContactIndexComponent from './Component/ContactComponent/ContactIndexComponent';

import './Component/MainComponent/main.css';

function App() {
    return (
        <div>
            <Router>
                <NavComponent/>
                <Routes>
                    <Route path='/' element={<HomeIndexComponent/>} exact/>
                    {/* <Route path='/history' element={<HistoryIndexComponent/>} exact/> */}
                    {/* <Route path='/shop' element={<ShopIndexComponent/>} exact/> */}
                    {/* <Route path='/portfolio' element={<PortfolioIndexComponent/>} exact/> */}
                    {/* <Route path='/blog' element={<BlogIndexComponent/>} exact/> */}
                    {/* <Route path='/storelocator' element={<StoreLocatorIndexComponent/>} exact/> */}
                    {/* <Route path='/faq' element={<FAQIndexComponent/>} exact/> */}
                    {/* <Route path='/contact' element={<ContactIndexComponent/>} exact/> */}
                </Routes>
                <FooterComponent/>
            </Router>
        </div>
    );
}

export default App;
