import React from "react";
import './main.css';

const FooterComponent =  () => {
    return (
        <>
        <footer>
            <div>
                <div className="footer-column">
                    <h4>AJMAN FURNITURE.</h4>
                    <p>
                    Shop online for furniture at Ajman Furniture. Browse our latest collection of furniture for living room, bedroom & more in UAE at an affordable price. Buy online now!
                    </p>
                    <div>
                        <a className="social-icons" href="www.facebook.com">FB.</a>
                        <a className="social-icons" href="www.facebook.com">TW.</a>
                        <a className="social-icons" href="www.facebook.com">IS.</a>
                        <a className="social-icons" href="www.facebook.com">LI.</a>
                    </div>
                </div>
                <div className="footer-column">
                    <h4>Studio</h4>
                    <a className="footer-links" href="https://www.asgharfurniture.ae/contact-us/">Visit Our Store</a>
                    <a className="footer-links" href="https://www.asgharfurniture.ae/about-us/">About Us</a>
                    <a className="footer-links" href="https://www.asgharfurniture.ae/blogs/">Our Blog</a>
                    <a className="footer-links" href="https://www.asgharfurniture.ae/about-us/">Craftmanship</a>
                    <a className="footer-links" href="https://www.asgharfurniture.ae/work-with-us/">Work With Us</a>
                </div>
                <div className="footer-column">
                    <h4>Shop Now</h4>
                    <a className="footer-links" href="https://www.asgharfurniture.ae/living-room/accent-arm-chair/">Accent / Arm Chair</a>
                    <a className="footer-links" href="https://www.asgharfurniture.ae/living-room/tv-cabinet/">TV Cabinet</a>
                    <a className="footer-links" href="https://www.asgharfurniture.ae/bed-room/wardrobe/">Wardrobe</a>
                    <a className="footer-links" href="https://www.asgharfurniture.ae/bed-room/dressing-table/">Dressing Table</a>
                    <a className="footer-links" href="https://www.asgharfurniture.ae/bed-room/nightstands/">Nightstand</a>
                </div>
                <div className="footer-column">
                    <h4>Payment Method</h4>
                    <p>
                    Select one of many supported payment providers from the list below.
                    </p>
                    <img src="./footer-logo-group.png" alt="Asghar Furniture Payment Method ApplePay SamsungPay Credit Card Visa"/>
                </div>
            </div>
        </footer>
        <div className="copy-rights">
            <p>© {new Date().getFullYear()} Ajman Furniture, All Rights Reserved</p>
        </div>
        </>

    );
}

export default FooterComponent;