import React from "react";

const viewfunc = () =>{
    window.location.href = 'https://asgharfurniture.ae'
}

const DescriptionComponent = () => {
    return(
        <section>
            <div className="description-block">
                <p>FIND ALL FOR YOUR HOME</p>
                <h1>Furniture for the new age</h1>
                <p>We provide all kind of furniture from bed to living room under one roof! <br/> The main thing about Ajman Furniture is our products can be customized as the way <br/> how you want. We deliver on time, absolutely free.</p>
                <button className="view-description-class" onClick={()=>viewfunc()}>View More</button>
            </div>
        </section>
    );
}

export default DescriptionComponent;